function formatarValor(valor) {
    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}
function formatarValorBRL(valor) {
    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}
function formatarPercentual(valor) {
    return valor.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 });
}
function removerAcentos(string) {
    var map = { "â": "a", "Â": "A", "à": "a", "À": "A", "á": "a", "Á": "A", "ã": "a", "Ã": "A", "ê": "e", "Ê": "E", "è": "e", "È": "E", "é": "e", "É": "E", "î": "i", "Î": "I", "ì": "i", "Ì": "I", "í": "i", "Í": "I", "õ": "o", "Õ": "O", "ô": "o", "Ô": "O", "ò": "o", "Ò": "O", "ó": "o", "Ó": "O", "ü": "u", "Ü": "U", "û": "u", "Û": "U", "ú": "u", "Ú": "U", "ù": "u", "Ù": "U", "ç": "c", "Ç": "C", " ": "" };
    return string.replace(/[\W\[\] ]/g, function (a) {
        return map[a] || a;
    });
}

function getLoaderModel() {
    return `<div class="col-12">
                <div class="alert alert-info">
                    <h5><i class="icon fas fa-circle-notch fa-spin"></i> Buscando</h5>
                </div>
            </div>`;
}

function blockScreen(data) {
    if (!data.block) {
        data.element ? $(data.element).unblock() : $.unblockUI();
        return
    }

    options = {
        message: `<div><img class="img-fluid mx-auto d-block" src="https://al-coworking.s3.amazonaws.com/assets/img/loader.gif"/>${data.message ? `<h1>${data.message}</h1>` : ""}</h1></div>`,
        baseZ: 9999,
        overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait'
        },
        css: {
            width: "100%",
            border: 0,
            padding: 0,
            backgroundColor: 'transparent'
        }
    };

    data.element ? data.element.block(options) : $.blockUI(options);
}

function getTraducaoDataTable(emptyTable) {
    return {
        "sEmptyTable": `<h4>${emptyTable}</h4>`,
        "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
        "sInfoFiltered": "(Filtrados de _MAX_ registros)",
        "sInfoPostFix": "",
        "sInfoThousands": ".",
        "sLengthMenu": "_MENU_ resultados por página",
        "sLoadingRecords": `<i class="fas fa-circle-notch fa-spin"></i> Buscando`,
        "sProcessing": "Buscando",
        "sZeroRecords": `<h4>${emptyTable}</h4>`,
        "sSearch": "Pesquisar",
        "oPaginate": {
            "sNext": "Próximo",
            "sPrevious": "Anterior",
            "sFirst": "Primeiro",
            "sLast": "Último"
        },
        "oAria": {
            "sSortAscending": ": Ordenar colunas de forma ascendente",
            "sSortDescending": ": Ordenar colunas de forma descendente"
        }
    };
}

function getMensagemErro(codigo) {
    switch (codigo) {
        case 401:
            return "Requisição não autorizada";
        case 404:
            return "Essa funcionalidade não foi encontrada no sistema, por favor entre em contato com a equipe de suporte";
        case 403:
            return "Requisição rejeitada pelo servidor, por favor entre em contato com a equipe de suporte";
        case 500:
            return "O servidor está com dificuldades para processar essa solicitação, por favor entre em contato com a equipe de suporte";
        case 503:
            return "O servidor está indisponível temporariamente, por favor entre em contato com a equipe de suporte";
        default:
            return "Por favor, entre em contato com a equipe de suporte";
    }
}

function getTimestamp() {
    return new Date().getTime();
}

function ucfirst(string) {
    return string.charAt().toUpperCase() + string.slice(1);
}

function getConfigDatepicker() {
    return {
        format: 'dd/mm/yyyy',
        autoclose: true,
        clearBtn: true,
        todayHighlight: true,
        language: "pt-BR",
    };
}

function isEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

function catchDefault(error) {
    if (error.response) {
        Swal.fire({
            icon: 'error',
            title: 'Atenção',
            html: getMensagemErro(error.response.status)
        });
    } else {
        if (!error.request) {
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                html: "Não foi possível processar a sua requisição"
            });
        }
    }
}

function getMesExtenso(mes) {
    let meses = {
        1: "Janeiro",
        01: "Janeiro",
        2: "Fevereiro",
        02: "Fevereiro",
        3: "Março",
        03: "Março",
        4: "Abril",
        04: "Abril",
        5: "Maio",
        05: "Maio",
        6: "Junho",
        06: "Junho",
        7: "Julho",
        07: "Julho",
        8: "Agosto",
        08: "Agosto",
        9: "Setembro",
        09: "Setembro",
        10: "Outubro",
        11: "Novembro",
        12: "Dezembro",
    };

    return meses[mes];
}
